.card1:hover {
    background:#00ffb6;
    border:1px solid #00ffb6;
    }
    
    .card1:hover .list-group-item{
    background:#00ffb6 !important
    }
    
    .card2:hover {
    background:#00C9FF;
    border:1px solid #00C9FF;
    }
    
    .card2:hover .list-group-item{
    background:#00C9FF !important
    }
    
    .card3:hover {
    background:#ff95e9;
    border:1px solid #ff95e9;
    }
    
    .card3:hover .list-group-item{
    background:#ff95e9 !important
    }
    
    
    .card:hover .btn-outline-dark{
    color:white;
    background:#212529;
    }

    #text_desktop {
        display: none;
    }

@media (hover: hover) {
    .card1:hover {
        background:#00ffb6;
        border:1px solid #00ffb6;
        }
        
        .card1:hover .list-group-item{
        background:#00ffb6 !important
        }
        
        .card2:hover {
        background:#00C9FF;
        border:1px solid #00C9FF;
        }
        
        .card2:hover .list-group-item{
        background:#00C9FF !important
        }
        
        .card3:hover {
        background:#ff95e9;
        border:1px solid #ff95e9;
        }
        
        .card3:hover .list-group-item{
        background:#ff95e9 !important
        }
        
        
        .card:hover .btn-outline-dark{
        color:white;
        background:#212529;
        }

        #hr_mobile {
            display: none;
        }

        #text_desktop {
            display: block;
        }
}