.footer{
background: linear-gradient(to bottom left, #D2D0FF, #6C63FF);
}

.footer-link{
color:white;
text-decoration: none
}

.footer-link:hover {
color:white;
text-decoration: underline;
}

#img_desktop {
    display: none;
}

@media (hover: hover) {
    .footer{
    background: linear-gradient(to bottom left, #D2D0FF, #6C63FF);
    }
    
    .footer-link{
    color:white;
    text-decoration: none
    }
    
    .footer-link:hover {
    color:white;
    text-decoration: underline;
    }

    #mobile_hr {
        display: none;
    }
}