.display-3{
    font-weight:700;
    }
    
    .purple{
    color:rgb(108,99,255);
    }
    .btn{
    border-radius:30px
    }

#img_desktop {
    display: none;
}

@media (hover: hover) {
    .display-3{
        font-weight:700;
        }
        
        .purple{
        color:rgb(108,99,255);
        }
        .btn{
        border-radius:30px
        }
    #br_mobile {
        display: none;
    }
    #img_desktop {
        display: block;
    }
}